export interface CRadio {
  time: string | null;
  status: CRadioStatus;
}

export enum CRadioStatus {
  CHECK,
  ERROR,
  NONE,
}
