import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "abortRequests" })
class AbortRequestsModule extends VuexModule {
  private signals: AbortController[] = [];

  @Mutation
  public addSignal(state: AbortController): void {
    this.signals.push(state);
  }

  @Action
  public push(state: AbortController): void {
    this.context.commit("addSignal", state);
  }

  @Mutation
  public cleanSignals(): void {
    for (const signal of this.signals) {
      !signal.signal.aborted && signal.abort();
    }
    this.signals = [];
  }

  @Action
  public abort(): void {
    this.context.commit("cleanSignals");
  }
}

export default AbortRequestsModule;
