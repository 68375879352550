import { AuthState } from "@/models/utils/auth-state.interface";
import { LoginRequest } from "@/models/utils/login-request.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "authenticate";

class AuthenticateService extends ApiService {
  login(request: LoginRequest) {
    return this.post<LoginRequest, AuthState>(`${CONTROLLER}/login`, request);
  }

  logout() {
    return this.getAll<void>(`${CONTROLLER}/logout`);
  }
  checkUserAuth() {
    return this.getAll<boolean>(`${CONTROLLER}/check-auth`);
  }
}

export const authenticate = new AuthenticateService();
