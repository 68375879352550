export enum ChartTab {
  LAST_WEEK = 7,
  LAST_MONTH = 30,
  LAST_YEAR = 365,
}

export enum ChartLabel {
  NO = "NO",
  PROBLEMS = "PROBLEMA",
  YES = "SI",
}
