export interface CTable {
  items: unknown;
  headers: TableHeader[];
  tableType: TableType;
  expandedItems: unknown;
  isLoading: boolean;
  itemKey: string | number;
  serverItemsLength: number;
}

export interface TableHeader {
  text: string;
  align?: string;
  value?: string;
  cellType: CellType;
  customProp?: string;
  customClass?: string;
  filterable?: boolean;
  width?: string;
}

export enum CellType {
  DEFAULT,
  ADDRESS,
  CHIPCIRCLE,
  CHIP,
  STATUS,
  DATE,
  TIME,
  EMPLOYEE,
  CHEVRON_RIGHT,
  ARCHIVE,
  HIDE_REGULAR_SERVICES,
  LIST,
  USER_CHIPCIRCLE,
  ALERT,
  ACTIONS,
}

export enum TableType {
  DEFAULT,
  DEFAULT_WITH_REFRESH,
  WITH_EXPANDABLE,
  WITH_SUBCATEGORIES,
}

export const EMERGENCIES_HEADER: TableHeader[] = [
  {
    text: "",
    value: "situationClass",
    cellType: CellType.CHIPCIRCLE,
    width: "30px",
    filterable: false,
    customProp: "situationClass",
  },
  {
    text: "Cliente",
    value: "customerName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Obiettivo",
    value: "officeName",
    cellType: CellType.ADDRESS,
    customProp: "address",
  },
  {
    text: "Servizio",
    value: "serviceName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Compagnia",
    value: "expertiseName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Operatore",
    value: "employeeFullName",
    cellType: CellType.EMPLOYEE,
    customProp: "isSubstitute",
    customClass: "c-label-bold c-txt-primary text-capitalize",
  },
  {
    text: "Turno",
    value: "startOn",
    cellType: CellType.TIME,
    align: "end",
  },
  {
    text: "",
    value: "endOn",
    cellType: CellType.TIME,
  },
  {
    text: "Telefono",
    value: "phone",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Timbratura",
    value: "stampInClass",
    cellType: CellType.CHIP,
    customProp: "stampInLabel",
  },
  {
    text: "",
    value: "equipmentAlert",
    cellType: CellType.ALERT,
    width: "30px",
    filterable: false,
  },
  {
    text: "Azioni",
    value: "lastActionId",
    cellType: CellType.STATUS,
    width: "200px",
    customClass: "c-body-bold c-txt-primary",
  },
];

export const ANOMALIES_HEADER: TableHeader[] = [
  {
    text: "",
    value: "situationClass",
    cellType: CellType.CHIPCIRCLE,
    width: "30px",
    filterable: false,
    customProp: "situationClass",
  },
  {
    text: "Cliente",
    value: "customerName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Obiettivo",
    value: "officeName",
    cellType: CellType.ADDRESS,
    customProp: "address",
  },
  {
    text: "Servizio",
    value: "serviceName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Compagnia",
    value: "expertiseName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Operatore",
    value: "employeeFullName",
    cellType: CellType.EMPLOYEE,
    customProp: "isSubstitute",
    customClass: "c-label-bold c-txt-primary text-capitalize",
  },
  {
    text: "Turno",
    value: "startOn",
    cellType: CellType.TIME,
    align: "end",
  },
  {
    text: "",
    value: "endOn",
    cellType: CellType.TIME,
  },
  {
    text: "Telefono",
    value: "phone",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Timbratura",
    value: "stampInClass",
    cellType: CellType.CHIP,
    customProp: "stampInLabel",
  },
  {
    text: "Geo",
    value: "gpsInClass",
    cellType: CellType.CHIP,
    customProp: "gpsInLabel",
  },
  {
    text: "Check",
    value: "keepAliveClass",
    cellType: CellType.CHIP,
    customProp: "keepAliveLabel",
  },
  {
    text: "",
    value: "equipmentAlert",
    cellType: CellType.ALERT,
    width: "30px",
    filterable: false,
  },
  {
    text: "Azioni",
    value: "",
    cellType: CellType.ARCHIVE,
    width: "110px",
  },
];

export const REGULAR_HEADER: TableHeader[] = [
  {
    text: "",
    value: "situationClass",
    cellType: CellType.CHIPCIRCLE,
    width: "30px",
    filterable: false,
    customProp: "situationClass",
  },
  {
    text: "Cliente",
    value: "customerName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Obiettivo",
    value: "officeName",
    cellType: CellType.ADDRESS,
    customProp: "address",
  },
  {
    text: "Servizio",
    value: "serviceName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Compagnia",
    value: "expertiseName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Operatore",
    value: "employeeFullName",
    cellType: CellType.EMPLOYEE,
    customProp: "isSubstitute",
    customClass: "c-label-bold c-txt-primary text-capitalize",
  },
  {
    text: "Turno",
    value: "startOn",
    cellType: CellType.TIME,
    align: "end",
  },
  {
    text: "",
    value: "endOn",
    cellType: CellType.TIME,
  },
  {
    text: "Telefono",
    value: "phone",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Timbratura",
    value: "stampInClass",
    cellType: CellType.CHIP,
    customProp: "stampInLabel",
  },
  {
    text: "Geo",
    value: "gpsInClass",
    cellType: CellType.CHIP,
    customProp: "gpsInLabel",
  },
  {
    text: "Check",
    value: "keepAliveClass",
    cellType: CellType.CHIP,
    customProp: "keepAliveLabel",
  },
  {
    text: "",
    value: "equipmentAlert",
    cellType: CellType.ALERT,
    width: "30px",
    filterable: false,
  },
];

export const ARCHIVED_HEADER: TableHeader[] = [
  {
    text: "",
    value: "refresh",
    cellType: CellType.CHIPCIRCLE,
    width: "30px",
    filterable: false,
    customProp: "situationClass",
  },
  {
    text: "Cliente",
    value: "customerName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Obiettivo",
    value: "officeName",
    cellType: CellType.ADDRESS,
    customProp: "address",
  },
  {
    text: "Servizio",
    value: "serviceName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Compagnia",
    value: "expertiseName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Operatore",
    value: "employeeFullName",
    cellType: CellType.EMPLOYEE,
    customProp: "isSubstitute",
    customClass: "c-label-bold c-txt-primary text-capitalize",
  },
  {
    text: "Data",
    value: "calendarDate",
    cellType: CellType.DATE,
  },
  {
    text: "Turno",
    value: "startOn",
    cellType: CellType.TIME,
  },
  {
    text: "",
    value: "endOn",
    cellType: CellType.TIME,
  },
  {
    text: "Telefono",
    value: "phone",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Inizio",
    value: "stampInClass",
    cellType: CellType.CHIP,
    customProp: "stampInLabel",
  },
  {
    text: "Fine",
    value: "stampOutClass",
    cellType: CellType.CHIP,
    customProp: "stampOutLabel",
  },
  {
    text: "",
    value: "equipmentAlert",
    cellType: CellType.ALERT,
    width: "30px",
    filterable: false,
  },
];

export const AUDIT_HEADER: TableHeader[] = [
  {
    text: "",
    value: "refresh",
    cellType: CellType.CHIPCIRCLE,
    width: "30px",
    filterable: false,
    customProp: "surveyClass",
  },
  {
    text: "Compagnia",
    value: "expertiseName",
    cellType: CellType.DEFAULT,
  },
  {
    text: "Obiettivo",
    value: "officeName",
    cellType: CellType.ADDRESS,
    customProp: "address",
  },
  {
    text: "Referente ispettivo",
    value: "userDescription",
    cellType: CellType.EMPLOYEE,
    customClass: "c-label-bold c-txt-primary text-capitalize",
  },
  {
    text: "Data",
    value: "endOn",
    cellType: CellType.DATE,
  },
  {
    text: "Inizio compilazione",
    value: "startOn",
    cellType: CellType.TIME,
  },
  {
    text: "Fine compilazione",
    value: "endOn",
    cellType: CellType.TIME,
  },
];

export const USERS_HEADER: TableHeader[] = [
  {
    text: "Abilitato",
    value: "isEnabled",
    cellType: CellType.USER_CHIPCIRCLE,
    width: "150px",
  },
  {
    text: "Username",
    value: "userName",
    cellType: CellType.DEFAULT,
    customClass: "c-label-regular c-txt-primary",
  },
  {
    text: "Cognome Nome",
    value: "userDescription",
    cellType: CellType.DEFAULT,
    customClass: "c-label-bold c-txt-primary text-capitalize",
  },
  {
    text: "Email",
    value: "email",
    cellType: CellType.DEFAULT,
    customClass: "c-label-regular c-txt-primary",
  },
  {
    text: "Ruolo",
    value: "userTypeName",
    cellType: CellType.DEFAULT,
    customClass: "c-label-regular c-txt-primary",
  },
  {
    text: "Compagnie",
    value: "expertises",
    cellType: CellType.LIST,
    customClass: "c-label-regular c-txt-primary",
    customProp: "expertiseName",
  },
];

export const BASE_EMAIL_CONFIG_HEADERS: TableHeader[] = [
  {
    text: "Destinatari",
    value: "emailToList",
    cellType: CellType.LIST,
    customClass: "c-label-regular c-txt-primary",
  },
  {
    text: "Cc",
    value: "emailCcList",
    cellType: CellType.LIST,
    customClass: "c-label-regular c-txt-primary",
  },
  {
    text: "Ccn",
    value: "emailBccList",
    cellType: CellType.LIST,
    customClass: "c-label-regular c-txt-primary",
  },
];

export const EMAIL_CONFIG_BY_USER_HEADERS: TableHeader[] = [
  {
    text: "Utente",
    value: "referenceKey",
    cellType: CellType.DEFAULT,
    customClass: "c-label-regular c-txt-primary",
  },
  {
    text: "Compagnia",
    value: "expertiseName",
    cellType: CellType.DEFAULT,
    customClass: "c-label-regular c-txt-primary",
  },
];

export const EMAIL_CONFIG_OTHER_HEADERS: TableHeader[] = [
  {
    text: "Referenza",
    value: "referenceKey",
    cellType: CellType.DEFAULT,
    customClass: "c-label-regular c-txt-primary",
  },
  {
    text: "Compagnia",
    value: "expertiseName",
    cellType: CellType.DEFAULT,
    customClass: "c-label-regular c-txt-primary",
  },
];

export const EMAIL_CONFIG_CUSTOMERS_HEADERS: TableHeader[] = [
  {
    text: "Clienti",
    value: "customers",
    cellType: CellType.LIST,
    customClass: "c-label-regular c-txt-primary",
    customProp: "customerName",
  },
];
