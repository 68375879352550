/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import CosmopolIcon from "./CosmopolIcon.vue";

export default {
  install(Vue, options = {}) {
    Vue.component(CosmopolIcon.name, CosmopolIcon);

    for (const [k, v] of Object.entries(options)) {
      if (CosmopolIcon.props[k]) {
        CosmopolIcon.props[k].default = v;
      }
    }
  },
  add(icons) {
    CosmopolIcon.add(icons);
  },
};

export { CosmopolIcon };
