import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: "#303840",
          lighten1: "#4B545E",
          lighten2: "#646C75",
          lighten3: "#ACB0B4",
          lighten4: "#D8DBDE",
          lighten5: "#F2F3F4",
          darken1: "#1E252D",
          darken2: "#131920",
          darken3: "#080B0F",
        },
        secondary: {
          base: "#00B4ED",
          lighten1: "#26D2F8",
          lighten2: "#63E1FF",
          lighten3: "#A9EFFF",
          lighten4: "#CDF6FF",
          lighten5: "#E6FAFF",
          darken1: "#0091E3",
          darken2: "#005EA2",
          darken3: "#02417B",
        },
        tertiary: {
          base: "#009688",
          lighten1: "#26A69A",
          lighten2: "#4DB6AC",
          lighten3: "#80CBC4",
          lighten4: "#B2DFDB",
          lighten5: "#E0F2F1",
          darken1: "#00897B",
          darken2: "#00796B",
          darken3: "#00695C",
        },
        accent: {
          base: "#D58000",
          lighten1: "#FEDC99",
          lighten2: "#FFEDCA",
          darken1: "#8C5400",
          darken2: "#3E1803",
        },
        error: {
          base: "#D1183A",
          lighten1: "#FFB5BE",
          lighten2: "#FFD9D9",
          darken1: "#761016",
          darken2: "#480509",
          darken3: "#D32F2F", //Cancel Btn
        },
        success: {
          base: "#007B37",
          lighten1: "#8FEFBA",
          lighten2: "#CDFAE1",
          darken1: "#0A572C",
          darken2: "#033419",
          darken3: "#388E3C", //Save Btn
        },
        background: {
          base: "#F2F3F4",
          lighten1: "#F3F3F3",
        },
        light: {
          base: "#FFFFFF",
        },
        dark: {
          base: "#000000",
        },
      },
    },
  },
});
