import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import Cookies from "js-cookie";
import NavigationDrawerModule from "@/store/modules/navigationDrawer.module";
import OverlayModule from "@/store/modules/overlay.module";
import SearchModule from "@/store/modules/search.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import TablesPaginationModule from "./modules/tables_pagination.module";
import TabModule from "./modules/tab.module";
import UtilsModule from "./modules/utils.module";
import FiltersModule from "./modules/filters.module";
import AuthModule from "./modules/auth.module";
import AbortRequestsModule from "./modules/abort-requests.module";

Vue.use(Vuex);

const vuexAuth = new VuexPersistence({
  key: "cosmopol-auth",
  restoreState: (key) => JSON.parse(Cookies.get(key) ? Cookies.get(key) : null),
  saveState: (key, state) => {
    Cookies.set(key, JSON.stringify(state), {
      expires: 30,
    } as Cookies.CookieAttributes);
  },
  modules: ["auth"],
});

const today = new Date();
const vuexFilters = new VuexPersistence({
  key: "cosmopol-filters",
  restoreState: (key) => JSON.parse(Cookies.get(key) ? Cookies.get(key) : null),
  saveState: (key, state) => {
    if (state["tables_pagination"]["pagination"] !== undefined)
      delete state["tables_pagination"]["pagination"];
    Cookies.set(key, JSON.stringify(state), {
      expires: new Date(
        today.getFullYear() + 99,
        today.getMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes()
      ),
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
    } as Cookies.CookieAttributes);
  },
  modules: ["tables_pagination"],
});

const store = new Vuex.Store({
  modules: {
    navigationDrawer: NavigationDrawerModule,
    overlay: OverlayModule,
    search: SearchModule,
    snackbar: SnackbarModule,
    tables_pagination: TablesPaginationModule,
    tab: TabModule,
    utils: UtilsModule,
    filters: FiltersModule,
    auth: AuthModule,
    abortRequests: AbortRequestsModule,
  },
  plugins: [vuexAuth.plugin, vuexFilters.plugin],
});

export default store;
