import Vue from "vue";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Pagination } from "@/models/utils/pagination.interface";

@Module({ namespaced: true, name: "tables_pagination" })
class TablesPaginationModule extends VuexModule {
  private pagination = {
    emergencies: {
      rows: 25,
      page: 1,
      search: null,
    } as Pagination,
    anomalies: {
      rows: 25,
      page: 1,
      search: null,
    } as Pagination,
    regular: {
      rows: 25,
      page: 1,
      search: null,
    } as Pagination,
    archived: {
      rows: 25,
      page: 1,
      search: null,
    } as Pagination,
    audit: {
      rows: 25,
      page: 1,
      search: null,
    } as Pagination,
    users: {
      rows: 25,
      page: 1,
      search: null,
    } as Pagination,
    emailConfig: {
      rows: 25,
      page: 1,
      search: null,
    } as Pagination,
  };
  private emergenciesRows = null as number;
  private anomaliesRows = null as number;
  private regularRows = null as number;
  private archivedRows = null as number;
  private auditRows = null as number;
  private usersRows = null as number;

  get emergencies(): Pagination {
    if (this.emergenciesRows) {
      Vue.set(this.pagination.emergencies, "rows", this.emergenciesRows);
    }
    return this.pagination.emergencies;
  }

  get anomalies(): Pagination {
    if (this.anomaliesRows) {
      Vue.set(this.pagination.anomalies, "rows", this.anomaliesRows);
    }
    return this.pagination.anomalies;
  }

  get regular(): Pagination {
    if (this.regularRows) {
      Vue.set(this.pagination.regular, "rows", this.regularRows);
    }
    return this.pagination.regular;
  }

  get archived(): Pagination {
    if (this.archivedRows) {
      Vue.set(this.pagination.archived, "rows", this.archivedRows);
    }
    return this.pagination.archived;
  }

  get audit(): Pagination {
    if (this.auditRows) {
      Vue.set(this.pagination.audit, "rows", this.auditRows);
    }
    return this.pagination.audit;
  }

  get users(): Pagination {
    if (this.usersRows) {
      Vue.set(this.pagination.users, "rows", this.usersRows);
    }
    return this.pagination.users;
  }

  get emailConfig(): Pagination {
    return this.pagination.users;
  }

  @Mutation
  private updateEmergenciesPagination(state: Pagination): void {
    state
      ? Vue.set(this.pagination, "emergencies", state)
      : Vue.set(this.pagination, "emergencies", null as Pagination);
    this.emergenciesRows =
      state && state.rows !== undefined ? state.rows : null;
  }

  @Action
  public setEmergenciesPagination(state: Pagination): void {
    this.context.commit("updateEmergenciesPagination", state);
  }

  @Mutation
  private updateAnomaliesPagination(state: Pagination): void {
    state
      ? Vue.set(this.pagination, "anomalies", state)
      : Vue.set(this.pagination, "anomalies", null as Pagination);
    this.anomaliesRows = state && state.rows !== undefined ? state.rows : null;
  }

  @Action
  public setAnomaliesPagination(state: Pagination): void {
    this.context.commit("updateAnomaliesPagination", state);
  }

  @Mutation
  private updateRegularPagination(state: Pagination): void {
    state
      ? Vue.set(this.pagination, "regular", state)
      : Vue.set(this.pagination, "regular", null as Pagination);
    this.regularRows = state && state.rows !== undefined ? state.rows : null;
  }

  @Action
  public setRegularPagination(state: Pagination): void {
    this.context.commit("updateRegularPagination", state);
  }

  @Mutation
  private updateArchivedPagination(state: Pagination): void {
    state
      ? Vue.set(this.pagination, "archived", state)
      : Vue.set(this.pagination, "archived", null as Pagination);
    this.archivedRows = state && state.rows !== undefined ? state.rows : null;
  }

  @Action
  public setArchivedPagination(state: Pagination): void {
    this.context.commit("updateArchivedPagination", state);
  }

  @Mutation
  private updateAuditPagination(state: Pagination): void {
    state
      ? Vue.set(this.pagination, "audit", state)
      : Vue.set(this.pagination, "audit", null as Pagination);
    this.auditRows = state && state.rows !== undefined ? state.rows : null;
  }

  @Action
  public setAuditPagination(state: Pagination): void {
    this.context.commit("updateAuditPagination", state);
  }

  @Mutation
  private updateUsersPagination(state: Pagination): void {
    state
      ? Vue.set(this.pagination, "users", state)
      : Vue.set(this.pagination, "users", null as Pagination);
    this.usersRows = state && state.rows !== undefined ? state.rows : null;
  }

  @Action
  public setUsersPagination(state: Pagination): void {
    this.context.commit("updateUsersPagination", state);
  }

  @Mutation
  private updateEmailConfigPagination(state: Pagination): void {
    Vue.set(this.pagination, "emailConfig", state);
  }

  @Action
  public setEmailConfigPagination(state: Pagination): void {
    this.context.commit("updateEmailConfigPagination", state);
  }
}

export default TablesPaginationModule;
