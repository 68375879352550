import { SnackbarState } from "@/models/utils/snackbar-state.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "snackbar" })
class SnackbarModule extends VuexModule {
  private snackbarState = {} as SnackbarState;

  get message(): string {
    return this.snackbarState ? this.snackbarState.message : "";
  }

  get type(): string {
    return this.snackbarState ? this.snackbarState.type : "";
  }

  get status(): boolean {
    return this.snackbarState ? this.snackbarState.snackbarStatus : false;
  }

  get top(): boolean {
    return this.snackbarState &&
      this.snackbarState.top != null &&
      this.snackbarState.top
      ? true
      : false;
  }

  get showActionButton(): boolean {
    return this.snackbarState &&
      this.snackbarState.showActionButton != null &&
      this.snackbarState.showActionButton
      ? true
      : false;
  }

  @Mutation
  private setSnackbar(state?: {
    message: string;
    type: string;
    top?: boolean;
    showActionButton?: boolean;
  }): void {
    if (state) {
      state.top != null && state.top;
      this.snackbarState = {
        message: state.message,
        type: state.type,
        snackbarStatus: true,
        top: state.top != null && state.top,
        showActionButton: false,
      };
    } else {
      this.snackbarState = {
        message: "",
        type: "",
        snackbarStatus: false,
        top: false,
        showActionButton: false,
      };
    }
  }

  @Action
  public showSnackbar(state: {
    message: string;
    type: string;
    top?: boolean;
  }): void {
    this.context.commit("setSnackbar", {
      message: state.message,
      type: state.type,
      top: state.top,
    });
  }

  @Action
  public hideSnackbar(): void {
    this.context.commit("setSnackbar");
  }
}

export default SnackbarModule;
