import { DrawerEmployee } from "@/models/utils/c-drawer.interace";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "navigationDrawer" })
class NavigationDrawerModule extends VuexModule {
  private stateValue = false;
  private selectedEmployee = null as DrawerEmployee;

  get drawerState(): boolean {
    return this.stateValue;
  }

  get employee(): DrawerEmployee {
    return this.selectedEmployee;
  }

  @Mutation
  private updateDrawerState(state: boolean): void {
    this.stateValue = state;
  }

  @Action
  public setDrawerState(state: boolean): void {
    this.context.commit("updateDrawerState", state);
  }

  @Mutation
  private updateSelectedEmployee(state: DrawerEmployee): void {
    this.selectedEmployee = state;
  }

  @Action
  public setSelectedEmployee(state: DrawerEmployee): void {
    this.context.commit("updateSelectedEmployee", state);
  }
}

export default NavigationDrawerModule;
