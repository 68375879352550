import { Action as IAction } from "@/models/entities/action.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "utils" })
class UtilsModule extends VuexModule {
  private actionsArr: IAction[] = [];

  get contextActions(): IAction[] {
    return this.actionsArr;
  }

  @Mutation
  private updateActions(state: IAction[]): void {
    this.actionsArr = state;
  }
  @Action
  public setActions(state: IAction[]): void {
    this.context.commit("updateActions", state);
  }
}

export default UtilsModule;
