import { IconItem } from "@/models/utils/c-icon-item.interface";

export const CosmopolWarning: IconItem = {
  name: "warning",
  style: "line",
  path: `<path d="M4.95215 16.3535L10.2152 5.85653C10.9531 4.38476 13.0538 4.38515 13.7912 5.85718L19.0494 16.3542C19.7156 17.6841 18.7486 19.25 17.2612 19.25H6.74001C5.25228 19.25 4.28535 17.6835 4.95215 16.3535Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 10V12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.5 16C12.5 16.2761 12.2761 16.5 12 16.5C11.7239 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.7239 15.5 12 15.5C12.2761 15.5 12.5 15.7239 12.5 16Z" />
  `,
  isStroked: true,
};

export const CosmopolClipboard: IconItem = {
  name: "clipboard",
  style: "line",
  path: `<path d="M9 6.75H7.75C6.64543 6.75 5.75 7.64543 5.75 8.75V17.25C5.75 18.3546 6.64543 19.25 7.75 19.25H16.25C17.3546 19.25 18.25 18.3546 18.25 17.25V8.75C18.25 7.64543 17.3546 6.75 16.25 6.75H15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14 8.25H10C9.44772 8.25 9 7.80228 9 7.25V5.75C9 5.19772 9.44772 4.75 10 4.75H14C14.5523 4.75 15 5.19772 15 5.75V7.25C15 7.80228 14.5523 8.25 14 8.25Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.75 12.25H14.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.75 15.25H14.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  `,
  isStroked: true,
};

export const CosmopolArchive: IconItem = {
  name: "archive",
  style: "line",
  path: `<path d="M18.25 8.75H5.75L6.57758 17.4396C6.67534 18.4661 7.53746 19.25 8.56857 19.25H15.4314C16.4625 19.25 17.3247 18.4661 17.4224 17.4396L18.25 8.75Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.25 5.75C19.25 5.19772 18.8023 4.75 18.25 4.75H5.75C5.19771 4.75 4.75 5.19772 4.75 5.75V7.75C4.75 8.30228 5.19772 8.75 5.75 8.75H18.25C18.8023 8.75 19.25 8.30228 19.25 7.75V5.75Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.75 13.25H14.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  `,
  isStroked: true,
};

export const CosmopolSearch: IconItem = {
  name: "search",
  style: "line",
  path: `<path d="M15.4077 16.4363L15.3944 16.423L15.3799 16.4348C14.1824 17.4009 12.66 17.9792 11.002 17.9792C7.14746 17.9792 4.02279 14.8545 4.02279 11C4.02279 7.14551 7.14746 4.02083 11.002 4.02083C14.8564 4.02083 17.9811 7.14551 17.9811 11C17.9811 12.6581 17.4029 14.1805 16.4367 15.3779L16.425 15.3925L16.4382 15.4057L19.7672 18.7347L19.7672 18.7348C20.0521 19.0186 20.0521 19.4814 19.7672 19.7652L19.7672 19.7653C19.4833 20.0501 19.0206 20.0501 18.7367 19.7653L18.7367 19.7653L15.4077 16.4363ZM11.002 5.47917C7.95245 5.47917 5.48112 7.95049 5.48112 11C5.48112 14.0495 7.95245 16.5208 11.002 16.5208C14.0515 16.5208 16.5228 14.0495 16.5228 11C16.5228 7.95049 14.0515 5.47917 11.002 5.47917Z" fill="#ACB0B4" stroke-width="0.0416667"/>`,
  isStroked: true,
};

export const CosmopolHelpCircle: IconItem = {
  name: "help-circle",
  style: "line",
  path: `<path d="M19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.75 10C9.75 10 10 7.75 12 7.75C14 7.75 14.25 9 14.25 10C14.25 10.7513 13.8266 11.5027 12.9798 11.8299C12.4647 12.0289 12 12.4477 12 13V13.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.5 16C12.5 16.2761 12.2761 16.5 12 16.5C11.7239 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.7239 15.5 12 15.5C12.2761 15.5 12.5 15.7239 12.5 16Z" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolCloseCircle: Omit<IconItem, "isStroked"> = {
  name: "close-circle",
  style: "line",
  path: `<path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5ZM4 12C4 7.58173 7.58173 4 12 4C16.4183 4 20 7.58173 20 12C20 16.4183 16.4183 20 12 20C7.58173 20 4 16.4183 4 12ZM9.21967 9.21967C9.51256 8.92678 9.98744 8.92678 10.2803 9.21967L12 10.9393L13.7197 9.21967C14.0126 8.92678 14.4874 8.92678 14.7803 9.21967C15.0732 9.51256 15.0732 9.98744 14.7803 10.2803L13.0607 12L14.7803 13.7197C15.0732 14.0126 15.0732 14.4874 14.7803 14.7803C14.4874 15.0732 14.0126 15.0732 13.7197 14.7803L12 13.0607L10.2803 14.7803C9.98744 15.0732 9.51256 15.0732 9.21967 14.7803C8.92678 14.4874 8.92678 14.0126 9.21967 13.7197L10.9393 12L9.21967 10.2803C8.92678 9.98744 8.92678 9.51256 9.21967 9.21967Z" />`,
};

export const CosmopolMinusCircle: IconItem = {
  name: "minus-circle",
  style: "line",
  path: `<path d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M15.25 12L8.75 12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolCheckCircle: Omit<IconItem, "isStroked"> = {
  name: "check-circle",
  style: "line",
  path: `<path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5ZM4 12C4 7.58173 7.58173 4 12 4C16.4183 4 20 7.58173 20 12C20 16.4183 16.4183 20 12 20C7.58173 20 4 16.4183 4 12Z" />
   <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6233 9.09948C14.9825 9.30563 15.1067 9.76399 14.9005 10.1233L12.6069 14.1206C11.9021 15.3488 10.1064 15.275 9.50475 13.993L9.07102 13.0686C8.89508 12.6936 9.05645 12.247 9.43144 12.071C9.80644 11.8951 10.2531 12.0564 10.429 12.4314L10.8627 13.3558C10.9487 13.5389 11.2051 13.5495 11.3059 13.374L13.5995 9.37674C13.8056 9.01747 14.264 8.89333 14.6233 9.09948Z" />`,
};

export const CosmopolPhone: Omit<IconItem, "isStroked"> = {
  name: "phone",
  style: "line",
  path: `<path fill-rule="evenodd" clip-rule="evenodd" d="M9.51602 4.834C9.37702 4.625 9.14302 4.5 8.89202 4.5H6.06702C4.92502 4.5 3.99902 5.426 3.99902 6.568C3.99902 14.263 10.236 20.5 17.931 20.5C19.073 20.5 19.999 19.574 19.999 18.432V15.607C19.999 15.356 19.874 15.122 19.665 14.983L16.558 12.912C16.26 12.713 15.864 12.753 15.612 13.005L14 14.617C13.953 14.664 13.885 14.697 13.825 14.666C13.27 14.379 12.369 13.84 11.481 12.993C10.59 12.143 10.067 11.227 9.80502 10.673C9.78102 10.621 9.81502 10.566 9.85602 10.525L11.494 8.887C11.746 8.635 11.786 8.239 11.587 7.941L9.51602 4.834ZM8.49002 6L9.99802 8.262L8.79502 9.464C8.30402 9.956 8.15202 10.686 8.44902 11.315C8.76202 11.975 9.38402 13.066 10.446 14.078C11.465 15.051 12.5 15.669 13.137 15.998C13.78 16.331 14.549 16.189 15.061 15.677L16.237 14.501L18.499 16.008V18.432C18.499 18.746 18.245 19 17.931 19C11.065 19 5.49902 13.434 5.49902 6.568C5.49902 6.254 5.75302 6 6.06702 6H8.49002Z" />`,
};

export const CosmopolAnnotationWarning: IconItem = {
  name: "annotation-warning",
  style: "line",
  path: `<path d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V14.25C19.25 15.3546 18.3546 16.25 17.25 16.25H14.625L12 19.25L9.375 16.25H6.75C5.64543 16.25 4.75 15.3546 4.75 14.25V6.75Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M12 8V10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M12.5 13C12.5 13.2761 12.2761 13.5 12 13.5C11.7239 13.5 11.5 13.2761 11.5 13C11.5 12.7239 11.7239 12.5 12 12.5C12.2761 12.5 12.5 12.7239 12.5 13Z" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolBriefcase: IconItem = {
  name: "brief-case",
  style: "line",
  path: `<path d="M4.75 9.75C4.75 8.64543 5.64543 7.75 6.75 7.75H17.25C18.3546 7.75 19.25 8.64543 19.25 9.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V9.75Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M8.75 7.5V6.75C8.75 5.64543 9.64543 4.75 10.75 4.75H13.25C14.3546 4.75 15.25 5.64543 15.25 6.75V7.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M5 13.25H19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M8.75 11.75V14.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M15.25 11.75V14.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   `,
  isStroked: true,
};

export const CosmopolUser: IconItem = {
  name: "user",
  style: "line",
  path: `<path d="M15.25 8.5C15.25 10.2949 13.7949 11.75 12 11.75C10.2051 11.75 8.75 10.2949 8.75 8.5C8.75 6.70507 10.2051 5.25 12 5.25C13.7949 5.25 15.25 6.70507 15.25 8.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M6.84772 19.75H17.1527C18.2946 19.75 19.1742 18.7681 18.641 17.7584C17.8566 16.2731 16.0682 14.5 12.0002 14.5C7.93223 14.5 6.14388 16.2731 5.35945 17.7584C4.82618 18.7681 5.7058 19.75 6.84772 19.75Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolClock: IconItem = {
  name: "clock",
  style: "line",
  path: `<path d="M19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12Z" stroke-width="1.5"/>
   <path d="M12 8V12L14 14" stroke-width="1.5" stroke-linecap="round"/>
   `,
  isStroked: true,
};

export const CosmopolAccounts: IconItem = {
  name: "accounts",
  style: "line",
  path: `<path d="M5.78189 19.75H13.2185C13.783 19.75 14.2272 19.2817 14.1147 18.7285C13.8042 17.2012 12.7899 14.5 9.5002 14.5C6.21052 14.5 5.19625 17.2012 4.88569 18.7285C4.77321 19.2817 5.21738 19.75 5.78189 19.75Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.75 14.5C17.8288 14.5 18.6802 16.6479 19.0239 18.196C19.2095 19.032 18.5333 19.75 17.6769 19.75H16.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.25 8C12.25 9.51878 11.0188 10.75 9.5 10.75C7.98122 10.75 6.75 9.51878 6.75 8C6.75 6.48122 7.98122 5.25 9.5 5.25C11.0188 5.25 12.25 6.48122 12.25 8Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.75 10.75C16.2688 10.75 17.25 9.51878 17.25 8C17.25 6.48122 16.2688 5.25 14.75 5.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  `,
  isStroked: true,
};

export const CosmopolPercentage: IconItem = {
  name: "percentage",
  style: "line",
  path: `<path d="M17.25 7.25L6.75 17.75"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.25 16.5C17.25 17.1904 16.6904 17.75 16 17.75C15.3096 17.75 14.75 17.1904 14.75 16.5C14.75 15.8096 15.3096 15.25 16 15.25C16.6904 15.25 17.25 15.8096 17.25 16.5Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.25 8.5C9.25 9.19036 8.69036 9.75 8 9.75C7.30964 9.75 6.75 9.19036 6.75 8.5C6.75 7.80964 7.30964 7.25 8 7.25C8.69036 7.25 9.25 7.80964 9.25 8.5Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  `,
  isStroked: true,
};

export const CosmopolArrowLeft: IconItem = {
  name: "arrow-left",
  style: "line",
  path: `<path d="M13.6668 9L6.3335 16L13.6668 23"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M25.6665 16H6.6665"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  `,
  isStroked: true,
};

export const CosmopolBuildingStore: IconItem = {
  name: "building-store",
  style: "line",
  path: `<path d="M6.75 19.5823H17.25C18.3546 19.5823 19.25 18.6868 19.25 17.5823V8.51525C19.25 8.22975 19.1889 7.94755 19.0707 7.68764L18.5329 6.50442C18.2084 5.79044 17.4965 5.33203 16.7122 5.33203H7.28783C6.50354 5.33203 5.79163 5.79044 5.46709 6.50443L4.92927 7.68764C4.81113 7.94755 4.75 8.22975 4.75 8.51525V17.5823C4.75 18.6868 5.64543 19.5823 6.75 19.5823Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.5 8.08203C9.5 9.32467 8.5 10.582 7 10.582C5.5 10.582 4.75 9.32467 4.75 8.08203" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.25 8.08203C19.25 9.32467 18.5 10.582 17 10.582C15.5 10.582 14.5 9.32467 14.5 8.08203" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.5 8.08203C14.5 9.32467 13.5 10.582 12 10.582C10.5 10.582 9.5 9.32467 9.5 8.08203" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.74951 16.0811C9.74951 14.9765 10.6449 14.0811 11.7495 14.0811H12.2495C13.3541 14.0811 14.2495 14.9765 14.2495 16.0811V19.5811H9.74951V16.0811Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolChart: IconItem = {
  name: "chart",
  style: "line",
  path: `<path d="M4.75 7.58203C4.75 6.47746 5.64543 5.58203 6.75 5.58203H17.25C18.3546 5.58203 19.25 6.47746 19.25 7.58203V18.082C19.25 19.1866 18.3546 20.082 17.25 20.082H6.75C5.64543 20.082 4.75 19.1866 4.75 18.082V7.58203Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M8.75 16.082V10.582" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M15.25 16.082V10.582" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M12 16.082V13.582" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolMessage: IconItem = {
  name: "message",
  style: "line",
  path: `<path d="M12 18.582C15.5 18.582 19.25 16.832 19.25 12.332C19.25 7.83203 15.5 6.08203 12 6.08203C8.5 6.08203 4.75 7.83203 4.75 12.332C4.75 13.3618 4.94639 14.2476 5.29123 15.0014C5.50618 15.4712 5.62675 15.9893 5.53154 16.4972L5.26934 17.8955C5.13974 18.5868 5.74527 19.1923 6.43651 19.0627L9.64388 18.4613C9.896 18.414 10.1545 18.4182 10.4078 18.4583C10.935 18.542 11.4704 18.582 12 18.582Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.5 12.332C9.5 12.6082 9.27614 12.832 9 12.832C8.72386 12.832 8.5 12.6082 8.5 12.332C8.5 12.0559 8.72386 11.832 9 11.832C9.27614 11.832 9.5 12.0559 9.5 12.332Z" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.5 12.332C12.5 12.6082 12.2761 12.832 12 12.832C11.7239 12.832 11.5 12.6082 11.5 12.332C11.5 12.0559 11.7239 11.832 12 11.832C12.2761 11.832 12.5 12.0559 12.5 12.332Z" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.5 12.332C15.5 12.6082 15.2761 12.832 15 12.832C14.7239 12.832 14.5 12.6082 14.5 12.332C14.5 12.0559 14.7239 11.832 15 11.832C15.2761 11.832 15.5 12.0559 15.5 12.332Z" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolCheck: IconItem = {
  name: "check",
  style: "line",
  path: `<path d="M5.75 13.6985L8.33995 17.2458C9.15171 18.3577 10.8179 18.3361 11.6006 17.2036L18.25 7.58203" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolChevronUp: Omit<IconItem, "isStroked"> = {
  name: "chevron-up",
  style: "line",
  path: `<path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.332C12.2086 10.332 12.4077 10.4189 12.5496 10.5717L15.7996 14.0717C16.0815 14.3752 16.0639 14.8498 15.7603 15.1316C15.4568 15.4135 14.9823 15.3959 14.7004 15.0924L12 12.1842L9.2996 15.0924C9.01775 15.3959 8.5432 15.4135 8.23966 15.1316C7.93613 14.8498 7.91856 14.3752 8.20041 14.0717L11.4504 10.5717C11.5923 10.4189 11.7915 10.332 12 10.332Z" fill="#303840"/>
   `,
};

export const CosmopolChevronDown: Omit<IconItem, "isStroked"> = {
  name: "chevron-down",
  style: "line",
  path: `<path fill-rule="evenodd" clip-rule="evenodd" d="M8.23966 10.5324C8.5432 10.2506 9.01775 10.2682 9.2996 10.5717L12 13.4798L14.7004 10.5717C14.9823 10.2682 15.4568 10.2506 15.7603 10.5324C16.0639 10.8143 16.0815 11.2888 15.7996 11.5924L12.5496 15.0924C12.4077 15.2452 12.2086 15.332 12 15.332C11.7915 15.332 11.5923 15.2452 11.4504 15.0924L8.20041 11.5924C7.91856 11.2888 7.93613 10.8143 8.23966 10.5324Z" fill="#303840"/>`,
};

export const CosmopolChevronRight: IconItem = {
  name: "chevron-right",
  style: "line",
  path: `<path d="M10.75 8.75L14.25 12L10.75 15.25"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolChevronLeft: IconItem = {
  name: "chevron-left",
  style: "line",
  path: `<path fill-rule="evenodd" clip-rule="evenodd" d="M10.3497 6.17975C10.5611 6.4074 10.5479 6.76331 10.3203 6.9747L8.13916 9L10.3203 11.0253C10.5479 11.2367 10.5611 11.5926 10.3497 11.8203C10.1383 12.0479 9.7824 12.0611 9.55475 11.8497L6.92975 9.4122C6.81513 9.30577 6.75 9.15642 6.75 9C6.75 8.84359 6.81513 8.69424 6.92975 8.58781L9.55475 6.15031C9.7824 5.93892 10.1383 5.9521 10.3497 6.17975Z" fill="#646C75"/>`,
  isStroked: true,
};

export const CosmopolTrash: IconItem = {
  name: "trash",
  style: "line",
  path: `<path d="M6.75 8.25L7.59115 17.9233C7.68102 18.9568 8.54622 19.75 9.58363 19.75H14.4164C15.4538 19.75 16.319 18.9568 16.4088 17.9233L17.25 8.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M9.75 8V7.25C9.75 6.14543 10.6454 5.25 11.75 5.25H12.25C13.3546 5.25 14.25 6.14543 14.25 7.25V8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M5 8.25H19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolPlus: IconItem = {
  name: "plus",
  style: "line",
  path: `<path d="M9 4.8125V14.1875" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M13.6875 9.5H4.3125" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolClose: IconItem = {
  name: "close",
  style: "line",
  path: `<path d="M12.9375 6.05273L5.0625 13.9277" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M5.0625 6.05273L12.9375 13.9277" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolCalendar: IconItem = {
  name: "calendar",
  style: "line",
  path: `  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 8.75C4.75 7.64543 5.64543 6.75 6.75 6.75H17.25C18.3546 6.75 19.25 7.64543 19.25 8.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V8.75Z"/>
   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 4.75V8.25"/>
   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 4.75V8.25"/>
   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.75 10.75H16.25"/>`,
  isStroked: true,
};

export const CosmopolEyeOff: IconItem = {
  name: "eye-off",
  style: "line",
  path: `<path d="M13.9685 7.5C14.2984 8.17392 14.4375 8.75585 14.4375 9C14.4375 9.75 13.125 13.6875 9 13.6875C8.45144 13.6875 7.95261 13.6179 7.5 13.4954M5.25 12.2015C4.02157 11.002 3.5625 9.44356 3.5625 9C3.5625 8.25 4.875 4.3125 9 4.3125C10.346 4.3125 11.3926 4.73177 12.1918 5.32273" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M14.4375 3.5625L3.5625 14.4375" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M7.80676 10.1932C7.14775 9.53423 7.14775 8.46577 7.80676 7.80676C8.46577 7.14775 9.53423 7.14775 10.1932 7.80676" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolEyeOn: IconItem = {
  name: "eye-on",
  style: "line",
  path: `<path d="M14.4375 9C14.4375 9.75 13.125 13.6875 9 13.6875C4.875 13.6875 3.5625 9.75 3.5625 9C3.5625 8.25 4.875 4.3125 9 4.3125C13.125 4.3125 14.4375 8.25 14.4375 9Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M10.6875 9C10.6875 9.93198 9.93198 10.6875 9 10.6875C8.06802 10.6875 7.3125 9.93198 7.3125 9C7.3125 8.06802 8.06802 7.3125 9 7.3125C9.93198 7.3125 10.6875 8.06802 10.6875 9Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolKey: IconItem = {
  name: "key",
  style: "line",
  path: `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 13.25C17.3472 13.25 19.25 11.3472 19.25 9C19.25 6.65279 17.3472 4.75 15 4.75C12.6528 4.75 10.75 6.65279 10.75 9C10.75 9.31012 10.7832 9.61248 10.8463 9.90372L4.75 16V19.25H8L8.75 18.5V16.75H10.5L11.75 15.5V13.75H13.5L14.0963 13.1537C14.3875 13.2168 14.6899 13.25 15 13.25Z"/>
   <path d="M16.5 8C16.5 8.27614 16.2761 8.5 16 8.5C15.7239 8.5 15.5 8.27614 15.5 8C15.5 7.72386 15.7239 7.5 16 7.5C16.2761 7.5 16.5 7.72386 16.5 8Z"/>`,
  isStroked: true,
};

export const CosmopolDoorEnter: IconItem = {
  name: "door-enter",
  style: "line",
  path: `<path d="M10.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V19.25H15.25V12.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12Z" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M4.75 19.25H19.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M16.25 9.25L13.75 7M13.75 7L16.25 4.75M13.75 7H19.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolPin: IconItem = {
  name: "pin",
  style: "line",
  path: `<path d="M18.25 11C18.25 15 12 19.25 12 19.25C12 19.25 5.75 15 5.75 11C5.75 7.5 8.68629 4.75 12 4.75C15.3137 4.75 18.25 7.5 18.25 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M14.25 11C14.25 12.2426 13.2426 13.25 12 13.25C10.7574 13.25 9.75 12.2426 9.75 11C9.75 9.75736 10.7574 8.75 12 8.75C13.2426 8.75 14.25 9.75736 14.25 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolHome: Omit<IconItem, "isStroked"> = {
  name: "home",
  style: "line",
  path: `<path fill-rule="evenodd" clip-rule="evenodd" d="M6.75183 20.0002H17.2518C18.7708 20.0002 20.0018 18.7692 20.0018 17.2502V9.75024C20.0018 9.50424 19.8808 9.27324 19.6778 9.13324L12.4278 4.13324C12.1718 3.95624 11.8328 3.95624 11.5758 4.13324L4.32583 9.13324C4.12283 9.27324 4.00183 9.50424 4.00183 9.75024V17.2502C4.00183 18.7692 5.23283 20.0002 6.75183 20.0002ZM15.0018 18.5002H17.2518C17.9428 18.5002 18.5018 17.9412 18.5018 17.2502V10.1442L12.0018 5.66124L5.50183 10.1442V17.2502C5.50183 17.9412 6.06183 18.5002 6.75183 18.5002H9.00183V15.7492C9.00183 14.2302 10.2328 12.9992 11.7518 12.9992H12.1438C12.1828 12.9992 12.2188 13.0022 12.2518 13.0082V12.9992C13.7698 12.9992 15.0018 14.2302 15.0018 15.7492V18.5002ZM10.5018 15.7492C10.5018 15.0592 11.0608 14.4992 11.7518 14.4992H12.2518C12.9418 14.4992 13.5018 15.0592 13.5018 15.7492V18.4992H10.5018V15.7492Z" fill="#303840"/>`,
};

export const CosmopolRefresh: IconItem = {
  name: "refresh",
  style: "line",
  path: `<path d="M11.25 4.75L8.75 7L11.25 9.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M12.75 19.25L15.25 17L12.75 14.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M9.75 7H13.25C16.5637 7 19.25 9.68629 19.25 13V13.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M14.25 17H10.75C7.43629 17 4.75 14.3137 4.75 11V10.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolExcel: Omit<IconItem, "isStroked"> = {
  name: "excel",
  style: "line",
  path: `<path d="M13.4243 11.5756C13.6586 11.81 13.6586 12.1899 13.4243 12.4242L11.8485 13.9999L13.4243 15.5756C13.6586 15.81 13.6586 16.1899 13.4243 16.4242C13.19 16.6585 12.8101 16.6585 12.5757 16.4242L11 14.8484L9.42426 16.4242C9.18994 16.6585 8.81004 16.6585 8.57573 16.4242C8.34142 16.1899 8.34142 15.81 8.57573 15.5756L10.1515 13.9999L8.57574 12.4242C8.34143 12.1899 8.34143 11.81 8.57574 11.5756C8.81006 11.3413 9.18996 11.3413 9.42427 11.5756L11 13.1514L12.5757 11.5756C12.81 11.3413 13.1899 11.3413 13.4243 11.5756Z" fill="#303840"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 6.75C5 5.23122 6.23122 4 7.75 4H14C14.1989 4 14.3897 4.07902 14.5303 4.21967L18.7803 8.46967C18.921 8.61032 19 8.80109 19 9V17.25C19 18.7688 17.7688 20 16.25 20H7.75C6.23122 20 5 18.7688 5 17.25V6.75ZM7.75 5.5C7.05964 5.5 6.5 6.05964 6.5 6.75V17.25C6.5 17.9404 7.05964 18.5 7.75 18.5H16.25C16.9404 18.5 17.5 17.9404 17.5 17.25V10H13.75C13.3358 10 13 9.66421 13 9.25V5.5H7.75ZM14.5 6.31066L16.6893 8.5H14.5V6.31066Z" fill="#303840"/>`,
};

export const CosmopolScroll: IconItem = {
  name: "scroll",
  style: "line",
  path: `<path d="M9.75 8.75H13.25M9.75 11.75H13.25M17.625 4.75C16.7275 4.75 16.25 5.75736 16.25 7V7.25M17.625 4.75C18.5225 4.75 19.25 5.75736 19.25 7V7.25H16.25M17.625 4.75H8.75C7.64543 4.75 6.75 5.64543 6.75 6.75V16.75M16.25 7.25V17C16.25 18.2426 15.2725 19.25 14.375 19.25M14.375 19.25C13.4775 19.25 12.75 18.2426 12.75 17V16.75H6.75M14.375 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V16.75H6.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>`,
  isStroked: true,
};

export const CosmopolSettings: IconItem = {
  name: "settings",
  style: "line",
  path: "<path d='M13.12 5.613a1 1 0 0 0-.991-.863h-.258a1 1 0 0 0-.99.863l-.087.632c-.056.403-.354.724-.732.874a5.225 5.225 0 0 0-.167.07c-.373.163-.81.15-1.136-.095l-.308-.23a1 1 0 0 0-1.307.092l-.188.188a1 1 0 0 0-.092 1.307l.23.308c.244.325.258.763.095 1.136a5.225 5.225 0 0 0-.07.167c-.15.378-.47.676-.874.732l-.632.087a1 1 0 0 0-.863.99v.258a1 1 0 0 0 .863.99l.632.087c.403.056.724.354.874.732l.07.167c.163.373.15.81-.095 1.136l-.23.308a1 1 0 0 0 .092 1.307l.188.188a1 1 0 0 0 1.307.093l.308-.231c.325-.244.763-.258 1.136-.095a5.4 5.4 0 0 0 .167.07c.378.15.676.47.732.874l.087.632a1 1 0 0 0 .99.863h.258a1 1 0 0 0 .99-.863l.087-.632c.056-.403.354-.724.732-.874a5.12 5.12 0 0 0 .167-.07c.373-.163.81-.15 1.136.095l.308.23a1 1 0 0 0 1.307-.092l.188-.188a1 1 0 0 0 .093-1.307l-.231-.308c-.244-.325-.258-.763-.095-1.136l.07-.167c.15-.378.47-.676.874-.732l.632-.087a1 1 0 0 0 .863-.99v-.258a1 1 0 0 0-.863-.99l-.632-.087c-.403-.056-.724-.354-.874-.732a5.168 5.168 0 0 0-.07-.167c-.163-.373-.15-.81.095-1.136l.23-.308a1 1 0 0 0-.092-1.307l-.188-.188a1 1 0 0 0-1.307-.092l-.308.23c-.325.244-.763.258-1.136.095a5.185 5.185 0 0 0-.167-.07c-.378-.15-.676-.47-.732-.874l-.087-.632z' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'></path><path d='M13.25 12a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0z' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'></path>",
  isStroked: true,
};

export const CosmopolDelete: IconItem = {
  name: "delete",
  style: "line",
  path: "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6.75 7.75l.841 9.673a2 2 0 0 0 1.993 1.827h4.832a2 2 0 0 0 1.993-1.827l.841-9.673'></path><path stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M9.75 7.5v-.75a2 2 0 0 1 2-2h.5a2 2 0 0 1 2 2v.75'></path><path stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M5 7.75h14'></path>",
  isStroked: true,
};

export const CosmopolEdit: IconItem = {
  name: "edit",
  style: "line",
  path: "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M4.75 19.25l4.25-1 9.293-9.293a1 1 0 0 0 0-1.414l-1.836-1.836a1 1 0 0 0-1.414 0L5.75 15l-1 4.25z'></path><path stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M19.25 19.25h-5.5'></path>",
  isStroked: true,
};
