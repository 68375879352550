import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as icons from "@/components/CosmopolIcon/icons";
import CosmopolIcon from "@/components/CosmopolIcon";
import "@/sass/style.sass";
import { CRadioStatus } from "./models/utils/c-radio.interface";
import { ChartTab } from "./models/utils/c-chart.interface";
import { AppBarTab } from "./models/utils/c-tab.interface";
import { BtnType } from "./models/utils/c-btn.interface";
import { CellType, TableType } from "./models/utils/c-table.interface";
import { CAlert } from "./models/utils/c-alert.enum";
import { AuditAnswerTypes } from "./models/utils/audit-answer.enum";

CosmopolIcon.add(Object.values({ ...icons }));
Vue.use(CosmopolIcon);

//#region [Interfaces]

Vue.prototype.CRadioStatus = CRadioStatus;
Vue.prototype.ChartTab = ChartTab;
Vue.prototype.AppBarTab = AppBarTab;
Vue.prototype.BtnType = BtnType;
Vue.prototype.CellType = CellType;
Vue.prototype.TableType = TableType;
Vue.prototype.CAlert = CAlert;
Vue.prototype.AuditAnswerTypes = AuditAnswerTypes;
declare module "vue/types/vue" {
  // Global properties can be declared
  interface Vue {
    CRadioStatus: typeof CRadioStatus;
    ChartTab: typeof ChartTab;
    AppBarTab: typeof AppBarTab;
    BtnType: typeof BtnType;
    CellType: typeof CellType;
    TableType: typeof TableType;
    CAlert: typeof CAlert;
    AuditAnswerTypes: typeof AuditAnswerTypes;
  }
}

//#endregion

//#region [Import Components]

const CosmopolAlert = () =>
  import(
    /* webpackChunkName: "CosmopolAlert" */ "@/components/CosmopolAlert.vue"
  );
const CosmopolAutocomplete = () =>
  import(
    /* webpackChunkName: "CosmopolAutocomplete" */ "@/components/CosmopolAutocomplete.vue"
  );
const CosmopolBtn = () =>
  import(/* webpackChunkName: "CosmopolBtn" */ "@/components/CosmopolBtn.vue");
const CosmopolChip = () =>
  import(
    /* webpackChunkName: "CosmopolChip" */ "@/components/CosmopolChip.vue"
  );
const CosmopolConfirmationDialog = () =>
  import(
    /* webpackChunkName: "CosmopolConfirmationDialog" */ "@/components/CosmopolConfirmationDialog.vue"
  );
const CosmopolDatePicker = () =>
  import(
    /* webpackChunkName: "CosmopolDatePicker" */ "@/components/CosmopolDatePicker.vue"
  );
const CosmopolDatatable = () =>
  import(
    /* webpackChunkName: "CosmopolDatatable" */ "@/components/CosmopolDatatable/CosmopolDatatable.vue"
  );
const CosmopolDrawer = () =>
  import(
    /* webpackChunkName: "CosmopolDrawer" */ "@/components/CosmopolDrawer/CosmopolDrawer.vue"
  );
const CosmopolFilterBtn = () =>
  import(
    /* webpackChunkName: "CosmopolFilterBtn" */ "@/components/CosmopolFilter/components/CosmopolFilterBtn.vue"
  );
const CosmopolFiltersGroup = () =>
  import(
    /* webpackChunkName: "CosmopolFiltersGroup" */ "@/components/CosmopolFilter/CosmopolFiltersGroup.vue"
  );
const CosmopolLabel = () =>
  import(
    /* webpackChunkName: "CosmopolLabel" */ "@/components/CosmopolLabel.vue"
  );
const CosmopolRadio = () =>
  import(
    /* webpackChunkName: "CosmopolRadio" */ "@/components/CosmopolRadio.vue"
  );
const CosmopolRadioBtn = () =>
  import(
    /* webpackChunkName: "CosmopolRadioBtn" */ "@/components/CosmopolRadioBtn.vue"
  );
const CosmopolSelect = () =>
  import(
    /* webpackChunkName: "CosmopolSelect" */ "@/components/CosmopolSelect.vue"
  );
const CosmopolStatusSelect = () =>
  import(
    /* webpackChunkName: "CosmopolStatusSelect" */ "@/components/CosmopolStatusSelect.vue"
  );
const CosmopolSubstitutes = () =>
  import(
    /* webpackChunkName: "CosmopolSubstitutes" */ "@/components/CosmopolSubstitudes/CosmopolSubstitutes.vue"
  );
const CosmopolSubstitutesAutocomplete = () =>
  import(
    /* webpackChunkName: "CosmopolSubstitutesAutocomplete" */ "@/components/CosmopolSubstitudes/components/CosmopolSubstitutesAutocomplete.vue"
  );
const CosmopolTextArea = () =>
  import(
    /* webpackChunkName: "CosmopolTextArea" */ "@/components/CosmopolTextArea.vue"
  );
const CosmopolTextField = () =>
  import(
    /* webpackChunkName: "CosmopolTextField" */ "@/components/CosmopolTextField.vue"
  );

Vue.component("cosmopol-alert", CosmopolAlert);
Vue.component("cosmopol-autocomplete", CosmopolAutocomplete);
Vue.component("cosmopol-btn", CosmopolBtn);
Vue.component("cosmopol-chip", CosmopolChip);
Vue.component("cosmopol-confirmation-dialog", CosmopolConfirmationDialog);
Vue.component("cosmopol-date-picker", CosmopolDatePicker);
Vue.component("cosmopol-datatable", CosmopolDatatable);
Vue.component("cosmopol-drawer", CosmopolDrawer);
Vue.component("cosmopol-filter-btn", CosmopolFilterBtn);
Vue.component("cosmopol-filter-group", CosmopolFiltersGroup);
Vue.component("cosmopol-label", CosmopolLabel);
Vue.component("cosmopol-radio", CosmopolRadio);
Vue.component("cosmopol-radio-btn", CosmopolRadioBtn);
Vue.component("cosmopol-select", CosmopolSelect);
Vue.component("cosmopol-status-select", CosmopolStatusSelect);
Vue.component("cosmopol-substitutes", CosmopolSubstitutes);
Vue.component(
  "cosmopol-substitutes-autocomplete",
  CosmopolSubstitutesAutocomplete
);
Vue.component("cosmopol-text-area", CosmopolTextArea);
Vue.component("cosmopol-text-field", CosmopolTextField);

//#endregion

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
