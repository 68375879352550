import { AppBarTab } from "@/models/utils/c-tab.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "tab" })
class TabModule extends VuexModule {
  private tab = AppBarTab.EMERGENCIES;

  get current(): AppBarTab {
    return this.tab;
  }

  @Mutation
  private updateTab(state: AppBarTab): void {
    this.tab = state;
  }

  @Action
  public setTab(state: AppBarTab): void {
    this.context.commit("updateTab", state);
  }
}

export default TabModule;
