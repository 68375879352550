import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "search" })
class SearchModule extends VuexModule {
  private searchStr = "";

  get search(): string {
    return this.searchStr.trim().toLowerCase();
  }

  @Mutation
  private updateSearch(state: string): void {
    state ? (this.searchStr = state) : (this.searchStr = "");
  }

  @Action
  public setSearch(state: string): void {
    this.context.commit("updateSearch", state);
  }
}

export default SearchModule;
