import { Roles } from "../entities/role.interface";

export interface Tab {
  id: AppBarTab;
  name: string;
  icon: string;
  color: string;
  to: string;
  access: Roles;
}

export enum AppBarTab {
  EMERGENCIES,
  ANOMALIES,
  REGULAR,
  ARCHIVED,
  AUDIT,
  EXAMPLES,
  USER,
  USERFORM,
  ERROR,
  EMAIL_CONFIG_ACCESS,
}
