import { AuthUser } from "@/models/entities/auth-user.interface";
import { Roles } from "@/models/entities/role.interface";
import { AuthState } from "@/models/utils/auth-state.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true, name: "auth" })
class AuthModule extends VuexModule {
  private authState: AuthState = null;
  private err: string = null;

  get token(): boolean {
    return this.authState ? this.authState.token : null;
  }

  get user(): AuthUser {
    return this.authState ? this.authState.user : null;
  }

  get userName(): string {
    return this.authState
      ? this.authState.user
        ? this.authState.user.userName
        : ""
      : "";
  }

  get roles(): Roles[] {
    return this.authState
      ? this.authState.user
        ? this.authState.user.roleIds
        : []
      : [];
  }

  get error(): string {
    return this.err ? this.err : "Email o password non corretti!";
  }

  @Mutation
  public setLoginError(state: string): void {
    this.err = state;
  }
  @Action
  public loginError(state: string): void {
    this.context.commit("setLoginError", state);
  }

  @Mutation
  public setLogin(state: AuthState): void {
    this.authState = state;
  }
  @Action
  public login(state: AuthState): void {
    this.context.commit("setLogin", state);
  }

  @Mutation
  public setLogout(): void {
    this.authState = null;
  }
  @Action
  public logout(): void {
    this.context.commit("setLogout");
  }
}

export default AuthModule;
