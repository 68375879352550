import { AuditRequest } from "@/models/requests/audit-request.interface";
import { WarningRequest } from "@/models/requests/warning-request.interface";
import { ArchivesFiltersValue } from "@/models/utils/archives-filters.interface";
import Vue from "vue";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "filters" })
class FiltersModule extends VuexModule {
  private filters = {
    anomalies: null as Omit<WarningRequest, "rows" | "page" | "search">,
    archived: null as ArchivesFiltersValue,
    audit: null as Omit<AuditRequest, "rows" | "page" | "search">,
  };

  get anomalies(): Omit<WarningRequest, "rows" | "page" | "search"> {
    return this.filters.anomalies;
  }

  get archived(): ArchivesFiltersValue {
    return this.filters.archived;
  }

  get audit(): Omit<AuditRequest, "rows" | "page" | "search"> {
    return this.filters.audit;
  }

  @Mutation
  private updateAnomaliesFilters(
    state: Omit<WarningRequest, "rows" | "page" | "search">
  ): void {
    Vue.set(this.filters, "anomalies", state);
  }
  @Action
  public setAnomaliesFilters(
    state: Omit<WarningRequest, "rows" | "page" | "search">
  ): void {
    this.context.commit("updateAnomaliesFilters", state);
  }

  @Mutation
  private updateArchivedFilters(state: ArchivesFiltersValue): void {
    Vue.set(this.filters, "archived", state);
  }
  @Action
  public setArchivedFilters(state: ArchivesFiltersValue): void {
    this.context.commit("updateArchivedFilters", state);
  }

  @Mutation
  private updateAuditFilters(
    state: Omit<WarningRequest, "rows" | "page" | "search">
  ): void {
    Vue.set(this.filters, "audit", state);
  }
  @Action
  public setAuditFilters(
    state: Omit<AuditRequest, "rows" | "page" | "search">
  ): void {
    this.context.commit("updateAuditFilters", state);
  }
}

export default FiltersModule;
