import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { AppBarTab } from "@/models/utils/c-tab.interface";
import { getModule } from "vuex-module-decorators";
import { Roles } from "@/models/entities/role.interface";
import store from "@/store";
import TabModule from "@/store/modules/tab.module";
import AuthModule from "@/store/modules/auth.module";
import AbortRequestsModule from "@/store/modules/abort-requests.module";
import { authenticate } from "@/services/api/authenticate.service";

const abortRequestsModule = getModule(AbortRequestsModule, store);
const authModule = getModule(AuthModule, store);
const tabModule = getModule(TabModule, store);

Vue.use(VueRouter);

const Anomalies = () =>
  import(
    /* webpackChunkName: "anomalies" */ "../views/Anomalies/Anomalies.vue"
  );
const Archived = () =>
  import(/* webpackChunkName: "archived" */ "../views/Archived/Archived.vue");
const Audit = () =>
  import(/* webpackChunkName: "Audit" */ "../views/Audit/Audit.vue");
const Emergencies = () =>
  import(
    /* webpackChunkName: "emergencies" */ "../views/Emergencies/Emergencies.vue"
  );
// const Example = () =>
//   import(/* webpackChunkName: "example" */ "../views/Example/Example.vue");
const Layout = () =>
  import(/* webpackChunkName: "layout" */ "../views/Layout/Default.vue");
const Regular = () =>
  import(/* webpackChunkName: "regular" */ "../views/Regular/Regular.vue");
const Users = () =>
  import(/* webpackChunkName: "Users" */ "../views/Users/UserList/Users.vue");
const UserForm = () =>
  import(
    /* webpackChunkName: "UserForm" */ "../views/Users/UserForm/UserForm.vue"
  );
const EmailConfig = () =>
  import(
    /* webpackChunkName: "UserForm" */ "../views/EmailConfig/EmailConfig.vue"
  );
const Login = () =>
  import(/* webpackChunkName: "Login" */ "../views/Login/Login.vue");
const Error = () =>
  import(/* webpackChunkName: "Error" */ "../views/Error/Error.vue");

const home = (r) => {
  return r.has(Roles.ANOMALIES_ACCESS)
    ? "Anomalies"
    : r.has(Roles.REGULAR_ACCESS)
    ? "Regular"
    : r.has(Roles.ARCHIVED_ACCESS)
    ? "Archived"
    : r.has(Roles.AUDIT_ACCESS)
    ? "Audit"
    : r.has(Roles.EMAIL_CONFIG_ACCESS)
    ? "EmailConfig"
    : r.has(Roles.USERS_ACCESS)
    ? "Users"
    : "Error";
};

const forbidden = (next) => {
  next({
    name: "Error",
    params: {
      code: "403",
      message: "Non hai i permessi per accedere alla pagina cercata!",
    },
  });
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/emergencies",
    component: Layout,
    children: [
      {
        path: "/emergencies",
        name: "Emergencies",
        component: Emergencies,
        beforeEnter: function (to, from, next) {
          const roles = new Set(authModule.roles);
          if (roles.has(Roles.EMERGENCIES_ACCESS)) {
            tabModule.setTab(AppBarTab.EMERGENCIES);
            next();
          } else {
            router.push({ name: home(roles) }).catch((e) => {
              e;
            });
          }
        },
      },
      {
        path: "/anomalies",
        name: "Anomalies",
        component: Anomalies,
        beforeEnter: function (to, from, next) {
          if (new Set(authModule.roles).has(Roles.ANOMALIES_ACCESS)) {
            tabModule.setTab(AppBarTab.ANOMALIES);
            next();
          } else {
            forbidden(next);
          }
        },
      },
      {
        path: "/regular",
        name: "Regular",
        component: Regular,
        beforeEnter: function (to, from, next) {
          const roles = new Set(authModule.roles);
          if (roles.has(Roles.REGULAR_ACCESS)) {
            tabModule.setTab(AppBarTab.REGULAR);
            next();
          } else {
            forbidden(next);
          }
        },
      },
      // {
      //   path: "/example",
      //   name: "Example",
      //   component: Example,
      //   beforeEnter: function (to, from, next) {
      //     tabModule.setTab(AppBarTab.EXAMPLES);
      //     next();
      //   },
      // },
      {
        path: "/archived",
        name: "Archived",
        component: Archived,
        beforeEnter: function (to, from, next) {
          if (new Set(authModule.roles).has(Roles.ARCHIVED_ACCESS)) {
            tabModule.setTab(AppBarTab.ARCHIVED);
            next();
          } else {
            forbidden(next);
          }
        },
      },
      {
        path: "/audit",
        name: "Audit",
        component: Audit,
        beforeEnter: function (to, from, next) {
          if (new Set(authModule.roles).has(Roles.AUDIT_ACCESS)) {
            tabModule.setTab(AppBarTab.AUDIT);
            next();
          } else {
            forbidden(next);
          }
        },
      },
      {
        path: "/users",
        name: "Users",
        component: Users,
        beforeEnter: function (to, from, next) {
          const roles = new Set(authModule.roles);
          if (roles.has(Roles.USERS_ACCESS)) {
            tabModule.setTab(AppBarTab.USER);
            next();
          } else {
            forbidden(next);
          }
        },
      },
      {
        path: "/user/:id",
        name: "UserForm",
        component: UserForm,
        beforeEnter: function (to, from, next) {
          if (new Set(authModule.roles).has(Roles.USERS_ACCESS)) {
            tabModule.setTab(AppBarTab.USERFORM);
            next();
          } else {
            forbidden(next);
          }
        },
      },
      {
        path: "/email-config",
        name: "EmailConfig",
        component: EmailConfig,
        beforeEnter: function (to, from, next) {
          // if (new Set(authModule.roles).has(Roles.EMAIL_CONFIG_ACCESS)) {
          tabModule.setTab(AppBarTab.EMAIL_CONFIG_ACCESS);
          next();
          // } else {
          //   forbidden(next);
          // }
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    name: "Logout",
    path: "/logout",
    component: () => {
      authModule.logout();
      router.push({ name: "login" });
    },
  },
  {
    path: "*",
    redirect: "/error",
    component: Layout,
    children: [
      {
        path: "/error",
        name: "Error",
        component: Error,
        props: true,
        beforeEnter: function (to, from, next) {
          tabModule.setTab(AppBarTab.ERROR);
          next();
        },
      },
      {
        path: "/mobile-error",
        name: "MobileError",
        component: Error,
        props: true,
        beforeEnter: function (to, from, next) {
          tabModule.setTab(AppBarTab.ERROR);
          next();
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  to.name !== from.name && abortRequestsModule.abort();
  authenticate.checkUserAuth().then((isAuth) => {
    isAuth.data === false && authModule.logout();
    if (to.name === "Logout" || authModule.token) {
      to.path === "/login" ? next({ path: "/" }) : next();
    } else {
      to.path === "/login" ? next() : next({ path: "/login" });
    }
  });
});

export default router;
