export interface Role {
  roleId: number;
  roleName: string;
  notes: string;
  roleGroup: string;
  roleSort: number;
}

export enum Roles {
  USERS_ACCESS = 1,
  EMERGENCIES_ACCESS = 2,
  ANOMALIES_ACCESS = 3,
  ARCHIVED_ACCESS = 4,
  REGULAR_ACCESS = 5,
  SEND_GEO_REQUEST = 6,
  INSERT_COORDINATES = 7,
  AUDIT_ACCESS = 8,
  CANT_VIEW_DETAILS = 9,
  CANT_EDIT_JOB_STATUS = 10,
  EMAIL_CONFIG_ACCESS = 11,
}
