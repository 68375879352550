import { CIcon } from "./c-icon.interface";

export interface CBtn {
  type: BtnType;
  color: string;
  icon: Omit<CIcon, "hoverFill" | "viewBox">;
  label: string;
  labelClass: string;
  btnClass?: string;
}

export enum BtnType {
  CUSTOM = "CUSTOM",
  SAVE = "SAVE",
  CANCEL = "CANCEL",
  FILTER = "FILTER",
  ERRROR = "ERRROR",
  ADDNOTE = "ADDNOTE",
  ARCHIVE = "ARCHIVE",
}
